import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="boper boper-hb">
          <div className="wrapper">
            <header
              style={{
                height: "120px"
              }}
            >
              <div
                style={{
                  height: "67px"
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/site/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a href="/site/see-whats-possible/" className="link">
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a href="/site/skora-z-niedoskonalosciami/" className="link">
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px"
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none"
                  }}
                >
                  <a href="/site/hydroboost/" className="link">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/skin-detox/" className="link">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/cellular-boost/" className="current">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
          </div>
          <section id="cellular" className="cellular">
            <div className="section section-banner">
              <div className="wrapper">
                <div className="desc">
                  <h2 className="text-1">
                    NEUTROGENA<sup>®</sup>
                  </h2>
                  <h1 className="text-2 pink light">Cellular Boost</h1>
                  <div className="text-3">
                    Stworzona we współpracy z dermatologami
                    <br />
                    <br />
                    Redukuje zmarszczki i widocznie poprawia elastyczność skóry
                    twarzy
                  </div>
                  <div className="stamps">
                    <p className="item">Witamina C*</p>
                    <p className="item">Kwas hialuronowy**</p>
                    <p className="item">Retinol**</p>
                  </div>
                  <div className="text-4">
                    <span className="nowrap">
                      * Pochodna Witaminy C, w przeciwzmarszczkowym kremie na
                      dzień SPF20 oraz przeciwzmarszczkowym kremie odnawiającym
                      na noc
                    </span>
                    <br />
                    ** W przeciwzmarszczkowym kremie pod oczy oraz koncentracie
                    przeciwzmarszczkowym
                  </div>
                </div>
              </div>
            </div>
            <div className="section section-1">
              <h2 className="text-1">
                NEUTROGENA<sup>®</sup>
              </h2>
              <h1 className="text-2">Cellular Boost</h1>
              <div className="wrapper videos  ">
                <div className="col">
                  <strong>
                    NEUTROGENA<sup>®</sup>
                  </strong>{" "}
                  we współpracy z dermatologami opracowała linię produktów
                  przeciwzmarszczkowych{" "}
                  <strong className="nowrap">Cellular Boost</strong>.
                  <br />
                  <br />
                  Unikalne formuły niwelują widoczne oznaki starzenia się skóry
                  spowodowane stresem komórkowym. Zmarszczki są zredukowane, a
                  koloryt oraz elastyczność widocznie poprawione. Skóra ponownie
                  wygląda na pełną energii, a jej młody wygląd jest zachowany na
                  dłuźej.
                  <br />
                  <br />
                  <span className="text-3">
                    Odkryj kompletny rytuał przeciwzmarszczkowy Cellular Boost.
                  </span>
                </div>
                <div className="col col-2">
                  <a
                    className="vid"
                    href="/themes/simple/img/cellular/Neutrogena_cellular_boost.mp4"
                  >
                    <img src="/themes/simple/img/cellular/video.png" alt />
                  </a>
                </div>
              </div>
            </div>
            <div className="section section-2">
              <h2 className="text-1 pink">
                NEUTROGENA<sup>®</sup> Cellular Boost <br />
                <span className="text-2">
                  Przeciwzmarszczkowy krem <br />
                  na dzień SPF 20
                </span>
              </h2>
              <div className="wrapper">
                <div className="col col-1">
                  <div className="packshot">
                    <img src="/themes/simple/img/cellular/packshot.png" />
                    <br />
                    <a className="btn" href="/product/86/">
                      Zobacz
                    </a>
                  </div>
                </div>
                <div className="col col-2">
                  Formuła potwierdzona klinicznie zawiera:
                  <div className="item">
                    <img
                      src="/themes/simple/img/cellular/icon-1.png"
                      className="icon"
                    />
                    <p className="text">
                      Przeciwstarzeniową technologię Hexinol™ , aby wspomagać
                      naturalną produkcję kolagenu i elastyny dla odnowy
                      naskórka***
                    </p>
                  </div>
                  <div className="item">
                    <img
                      src="/themes/simple/img/cellular/icon-2.png"
                      className="icon"
                    />
                    <p className="text">
                      Witaminę C*, aby pomóc wyrównać niejednolity koloryt i
                      zredukować przebarwienia.
                    </p>
                  </div>
                  <div className="item">
                    <img
                      src="/themes/simple/img/cellular/icon-3.png"
                      className="icon"
                    />
                    <p className="text">
                      Zawiera filtr SPF 20, aby chronić Twoją skórę przed
                      szkodliwym działaniem promieni słonecznych.
                    </p>
                  </div>
                  <div className="item">
                    <img
                      src="/themes/simple/img/cellular/icon-4.png"
                      className="icon"
                    />
                    <p className="text">
                      Widoczne rezultaty już po 4 tygodniach**.
                    </p>
                  </div>
                  <div className="item">
                    <img
                      src="/themes/simple/img/cellular/icon-5.png"
                      className="icon"
                    />
                    <p className="text">
                      Formuła potwierdzona klinicznie. Odpowiedni dla skóry
                      wrażliwej.
                    </p>
                  </div>
                </div>
                <div className="info">
                  <span className="item">* Pochodna Witaminy C</span>
                  <span className="item">
                    ** Badania kliniczne, 42 kobiety, stosowanie dwa razy
                    dziennie
                  </span>
                  <span className="item">*** Test in vitro.</span>
                </div>
              </div>
            </div>
            <div className="section section-3">
              <h2 className="text-1">
                Wypróbuj inne produkty z linii
                <br />
                <span className="text-2">
                  NEUTROGENA<sup>®</sup> Cellular Boost{" "}
                </span>
              </h2>
              <div className="wrapper">
                <div className="col col-1">
                  <img
                    className="img"
                    src="/themes/simple/img/cellular/produkt-1.png"
                  />
                  <div className="desc">
                    NEUTROGENA<sup>®</sup>
                    <br /> Cellular Boost
                    <br /> Koncentrat przeciw-
                    <br /> zmarszczkowy
                    <br />
                    <br />
                    <a href="/product/88/" className="btn">
                      Zobacz
                    </a>
                  </div>
                </div>
                <div className="col col-2">
                  <img
                    className="img"
                    src="/themes/simple/img/cellular/produkt-2.png"
                    height="340"
                    style={{
                      "-ms-flex-item-align": "flex-end",
                      "align-self": "flex-end"
                    }}
                  />
                  <div className="desc">
                    NEUTROGENA<sup>®</sup>
                    <br /> Cellular Boost
                    <br /> Przeciwzmarszczkowy krem pod oczy
                    <br />
                    <br />
                    <a className="btn" href="/product/87/">
                      Zobacz
                    </a>
                  </div>
                </div>
                <div className="col col-3">
                  <div className="desc">
                    NEUTROGENA<sup>®</sup>
                    <br /> Cellular Boost
                    <br /> Odnawiający krem przeciwzmarszczkowy <br />
                    na noc
                    <br />
                    <br />
                    <a className="btn" href="/product/86/">
                      Zobacz
                    </a>
                  </div>
                  <img
                    className="img"
                    src="/themes/simple/img/cellular/produkt-3.png"
                  />
                </div>
              </div>
            </div>
          </section>
          <footer>
            <div className="wrapper">
              <nav>
                <a href="/site/" className="logo2" />
                <a href="/site/nota-prawna/">Nota Prawna</a>
                <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
                <a href="/site/cookies/">Polityka cookies</a>
                 <a href="/site/kontakt/">Zachęcamy do kontaktu</a>                   
                           <span>© Johnson &amp; Johnson Poland Sp. z o.o. 2013 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
              <p>
                Strona zarządzana jest przez Johnson &amp; Johnson Poland Sp. z
                o. o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
                Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
                113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
                Johnson &amp; Johnson Poland Sp. z o. o. ponosi wyłączną
                odpowiedzialność za treści zawarte na niniejszej stronie.
                <br /> Strona jest przeznaczona dla użytkowników z Polski.
                Ostatnia aktualizacja: 25/10/2013
                <br />{" "}
              </p>{" "}
              <p>
                <span>
                  Strona została zoptymalizowana pod kątem przeglądarek:
                  Internet Explorer 7 i wyższych, Mozilla Firefox, Google
                  Chrome, Opera, Safari
                </span>{" "}
                <span className="right">
                  Zalecana rozdzielczość: 1024 x 768 pikseli
                </span>{" "}
              </p>
            </div>
          </footer>
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');"
            }}
          />
          <script type="text/plain" class="optanon-category-4" 
            dangerouslySetInnerHTML={{
              __html:
                "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n"
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
            }}
          />
        </div>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "      \n$(function() {\n$('#cellular nav a,#cellular .videos > a').click(function() {\n$('html,body').animate({\nscrollTop: $($(this).attr('href')).offset().top+'px'\n},1000);\nreturn false;\n}).each(function() {\nvar h = $(this).attr('href');\n$(this).attr('href',h.substring(h.indexOf('#')));\n});\n$('#cellular a.vid').click(function(e) {\ne.preventDefault();\n$('#cellular .videos').append('<figure><div><video controls preload=\"auto\"><source type=\"video/mp4\" src=\"'+$(this).attr('href')+'\" /></div><dfn></dfn></figure>');\n$('#cellular .videos figure video').each(function() {\nthis.load();\n$(this).on('canplaythrough',function() {\nthis.play();\n});\n});\n$('#cellular .videos figure dfn').click(function() {\n$('#cellular .videos figure video').each(function() {\nthis.pause();\n$(this).remove();             \n}); \n$('#cellular .videos figure').empty().remove();\n});\nreturn false;\n});\n$('#cellular a.ytvid').click(function() {\n$('body').append('<section class=\"yt\"><div><iframe src=\"'+$(this).attr('href')+'\"></iframe></div><dfn></dfn></section>');\n$('body > section.yt dfn').click(function() {\n$('body > section.yt').remove();\nreturn false;\n});\nreturn false;\n});\n});\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
